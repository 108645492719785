import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SVGSeparator from '../components/project-blocks/separator'
import InformationSectionBlock from '../components/project-blocks/information-section'
import TextAndImageBlock from '../components/project-blocks/text-and-image'
import TextLargeAndSmallBlock from '../components/project-blocks/text-large-and-small'

import { informationSectionData } from '../data/case-study-data--watch-apps'

// import appleWatchHeroImage from '../images/apple-watch-apps--hero-image.png'
// import appleWatchImage from '../images/apple-watch-apps--apple-watch-image.jpg'
// import appleWatchCompanionApps from '../images/apple-watch-apps--hero-image.png'
// import appleWatchTodayRedesign from '../images/apple-watch-apps--today-redesign.png'
// import appleWatchNutrientsRedesign from '../images/apple-watch-apps--nutrients-redesign.png'
// import appleWatchMindfulnessMinutesRedesign from '../images/apple-watch-apps--mindfulness-minutes-redesign.png'
// import appleWatchMonthlyWorkoutsRedesign from '../images/apple-watch-apps--monthly-workouts-redesign.png'
// import appleWatchHistoryRedesign from '../images/apple-watch-apps--history-redesign.png'
// import appleWatchWorkoutMapRedesign from '../images/apple-watch-apps--workout-map-redesign.png'

// import ProjectStyles from '../components/project-styles.module.less'

const HeaderImage = styled.div`
	height: auto;

	margin-bottom: 12px;

	& > * {
		width: auto;
		max-height: 80vh;
		/* height: fill-available; */
	}
`

const HeadingGroup = styled.header`
	display: grid;
	grid-gap: 6px;
`

const Title = styled.h1`
	font-size: 24px;
	font-weight: 500;
	letter-spacing: 1px;

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
		font-size: 32px;
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const Subtitle = styled.h2`
	font-size: 18px;
	opacity: 0.75;

	@media (min-width: 768px) {
		font-size: 21px;
	}
`

const WatchApps = ({ data }) => (
	<Layout>
		<SEO title="Castle UX / UI Case Study" />
		<HeaderImage>
			<Img
				fluid={data.heroImage.childImageSharp.fluid}
				alt="Apple Watch Companion Apps Redesigned Screens"
				imgStyle={{ objectFit: 'cover' }}
			/>
		</HeaderImage>
		<HeadingGroup>
			<Title>Apple Watch Companion Apps Rethought</Title>
			<Subtitle>UX/UI Case Study</Subtitle>
		</HeadingGroup>
		<SVGSeparator />
		<InformationSectionBlock data={informationSectionData} />
		<SVGSeparator />
		<TextAndImageBlock
			heading={'The Product'}
			paragraphs={[
				'Apple Watch is a line of smart watches designed, developed, and marketed by Apple Inc. It incorporates fitness tracking and health-oriented capabilities with integration with iOS and other Apple products and services.',
				'First introduced in April 2015, there have been four hardware and software revisions—each time adding additional functionality such as water-resistance, cell connectivity, and an EKG.',
				'Apple Watch exists in a technology sector known as wearables. Wearable Computers are small computing devices that are worn under, with, or on top of clothing. Wearables can be for general use or for specialized purposes such as fitness trackers. The Apple Watch collects a lot of data from its wearer with help from the attached iPhone. These data include — Steps, Activity (Move, Exercise, Stand), Stand Hours, Exercise Minutes, Workouts, Flights Climbed, Sleep Analysis, Heart Rate, and more.'
			]}
			imageData={data.appleWatchImage}
			altText={'Image of an Apple Watch'}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'The Companion Apps'}
			paragraphs={[
				'The Apple Watch has three companion apps. They are: the Watch app, where users can set up the watch, configure watch faces, and add apps; the Activity app, where users can see their daily progress, workouts, and achievements; and the Apple Health app, where users can access all of their health data, see their data over time, and set up their medical ID.'
			]}
			imageData={data.companionApps}
			altText={''}
			switch={true}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Today Redesign'}
			paragraphs={[
				'The current design for the Apple Health today screen is just the data. Whatever the health app gets hooked to, it displays it with little insight or priority.',
				'For the redesign, I wanted to add some insight, goals and categorization to the Today screen. The first section shows personal insight into the user’s health. For example, it could show that they are on pace to meet their activity or steps goals, or, the app could give people insight into maintaining their long-term mental health by having a consistent sleep schedule when they didn’t meet their sleep goals. Other goals can be added such as nutrition, workouts, or weight. Other insights could include heart health, weight fluctuations, or nutrition proportions.'
			]}
			imageData={data.todayRedesign}
			altText={'Image of an Apple Watch'}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Nutrients Redesign'}
			paragraphs={[
				'The current design for the Apple Health nutrients screen is a (kind of a judgemental) headline, a video, and the data. There’s no insight at all; who knows if 80mg of calcium is good or not.',
				'For the redesign, I wanted to at least add some basic nutritional visualization. One of the most basic is displaying the proportions of macronutrients i.e. carbohydrates, fat, and protein. There are goals for the user to hit, depending on the macronutrient, so they can maybe skip the steak if they’ve disproportionately consumed protein that day so far. There’s lots that could be done but a basic visualization won’t get Apple into trouble and help their users live a more informed and healthy lifestyle.'
			]}
			imageData={data.nutrientsRedesign}
			altText={'Nutrients Redesign App Screens Before and After'}
			switch={true}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Mindfulness Minutes Redesign'}
			paragraphs={[
				'The current design for the Apple Health mindful minutes screen is similar to many other data such as workouts, weight, etc. It’s the only stat in the mindfulness section of the health data is mindfulness minute, just having this generic chart isn’t very interesting.',
				'For the redesign, I decided to replace the bars with a flower (of sorts). There are seven breaths in each “mindful minute.” As the mindful minutes increase so to does the flower’s height and pedal number by breathes.'
			]}
			imageData={data.mindfulnessMinutesRedesign}
			altText={'Mindfulness Minutes Redesign App Screens Before and After'}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Monthly Workouts Redesign'}
			paragraphs={[
				'The current design for the monthly workouts is just a glorified table view. It just lists the data for each month and an average of those data.',
				'For the redesign, I simply added a bar graph for amount of workouts in the month, the average time of those workouts, and the calories burnt during that month. This helps the user see how much they are working out compared to other months and whether they’re achieving their workout goals to maintain health.'
			]}
			imageData={data.monthlyWorkoutsRedesign}
			altText={'Monthly Workouts Redesign App Screens Before and After'}
			contain={true}
			switch={true}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Workout History Redesign'}
			paragraphs={[
				'The current design for the History tab shows whether or not the user filled the rings and whether they had a workout that day. There’s a lot of data shown but little insight.',
				'For the redesign, I kept the all rings view and added views for each ring. This way the user can see if they workout better some days of the week, if they missed their goal that day, and if they are generally are where they want to be for each ring.'
			]}
			imageData={data.historyRedesign}
			altText={'Workout History Redesign App Screens Before and After'}
			contain={true}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Workout Map Redesign'}
			paragraphs={[
				'The current design for the workout map left something to be desired. The only thing the map has is a trail of how fast the user was moving as well as start and stop locations.',
				'For the redesign, graphs were added below the map to give context to the workout trail. The user can also swipe a graph to see a comparison to other workouts of similar type. As the user swipes down the graph collapse to a smaller view aiding comparison between measured data.'
			]}
			imageData={data.workoutMapRedesign}
			altText={'Workout Map Redesign App Screens Before and After'}
			contain={true}
			switch={true}
		/>
		<SVGSeparator />
		<TextLargeAndSmallBlock
			heading={'Conclusions'}
			paragraphs={[
				'The Apple Watch is one of the best smart watches available today. There are numerous stories of people improving their health through increased activity, people learning of serious heart conditions potentially saving lives, and of detecting accidental falls then contacting help.',
				'One of the areas in which Apple has fallen short is the app experience companioning the hardware. They could help many more people reach their goals by not just displaying their data but interpreting the data to make it useful. The user had this many steps yesterday, slept an average of 7 1/2 hours last week, stood up only half as much as they should have, so what? Tell the user why that matters. Maybe then, we’d finally reach our goals.'
			]}
		/>
		<SVGSeparator />
		<Link to="/">← Back</Link>
	</Layout>
)

WatchApps.propTypes = {
	data: PropTypes.any.isRequired
}

export const pageQuery = graphql`
	query {
		heroImage: file(
			relativePath: { regex: "/apple-watch-apps--hero-image.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		appleWatchImage: file(
			relativePath: { regex: "/apple-watch-apps--apple-watch-image.jpg/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		companionApps: file(
			relativePath: { regex: "/apple-watch-apps--hero-image.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		todayRedesign: file(
			relativePath: { regex: "/apple-watch-apps--today-redesign.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		nutrientsRedesign: file(
			relativePath: { regex: "/apple-watch-apps--nutrients-redesign.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		mindfulnessMinutesRedesign: file(
			relativePath: {
				regex: "/apple-watch-apps--mindfulness-minutes-redesign.png/"
			}
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		monthlyWorkoutsRedesign: file(
			relativePath: {
				regex: "/apple-watch-apps--monthly-workouts-redesign.png/"
			}
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		historyRedesign: file(
			relativePath: { regex: "/apple-watch-apps--history-redesign.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		workoutMapRedesign: file(
			relativePath: { regex: "/apple-watch-apps--workout-map-redesign.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`

export default WatchApps
